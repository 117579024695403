(function ($) {
    var $navlink = $('.main-nav__item');
    var $navMenu = $('.navbar-collapse');

    function scrollToId(id) {
        $('html,body').animate({
            scrollTop: $(id).offset().top
        }, 600);
    }

    $navlink.on('click', function (event) {
        event.preventDefault();
        $navMenu.removeClass('show');
        scrollToId($(this).attr('href'));
    });
})(jQuery);