(function ($) {
    var $slides = $('.slide-in');

    function checkSlide() {
        $slides.each(function () {
            var slideInHeight = ($(window).scrollTop() + $(window).innerHeight()) - $(this).height() / 2;
            var slideBottomPosition = $(this).offset().top + $(this).innerHeight();
            var slideHalfShown = slideInHeight > $(this).offset().top;
            var slideNotScrolled = $(window).scrollTop() < slideBottomPosition;
            if (slideHalfShown && slideNotScrolled) {
                $(this).addClass('slide-in--active');
            }
        })
    }


    $(window).on('scroll', function () {
        checkSlide();
    });
})(jQuery);